import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import InnerSiteTest from '../pages/InnerSiteTest';
import LectorSettings from '../pages/settings/lectorSettings/LectorSettings';
import Settings from '../pages/settings/SettingsPage';
import LessonEditPage from '../pages/lesson/LessonEditPage';
import LessonPage from '../pages/lesson/LessonPage';
import Reviews from '../pages/reviews/Reviews';
import Contact from '../pages/contact/Contact';
import About from '../pages/about/About';
import Pricelist from '../pages/pricelist/Pricelist';
import StudentsPage from '../pages/student/StudentsPage';
import StudentPage from '../pages/student/StudentPage';
import StudentEditPage from '../pages/student/StudentEditPage';
import LectorPaymentsPage from '../pages/payments/LectorPaymentsPage';
import StudentPaymentsPage from '../pages/payments/StudentPaymentsPage';
import CoursesPage from '../pages/course/CoursesPage';
import CoursePage from '../pages/course/CoursePage';
import CourseEditPage from '../pages/course/CourseEditPage';
import StudentSettings from '../pages/settings/studentSettings/StudentSettings';
import AdminSettings from '../pages/settings/adminSettings/AdminSettings';
import LectorCalendarPage from '../pages/calendar/LectorCalendarPage';
import StudentCalendarPage from '../pages/calendar/StudentCalendarPage';
import DedicatedTimesEditPage from '../pages/dedicatedTime/DedicatedTimesEditPage';
import SignInPage from '../pages/SignIn/SignInPage';
import SignUpPage from '../pages/authentication/SignUpPage';
import PasswordRecoveryPage from '../pages/authentication/PasswordRecoveryPage';
import VisitorCalendarPage from '../pages/calendar/VisitorCalendarPage';
import PasswordChangePage from '../pages/authentication/PasswordChangePage';
import ClassroomPage from '../pages/classroom/ClassroomPage';
import ClassroomEditPage from '../pages/classroom/ClassroomEditPage';
import BlogPage from '../pages/blog/BlogPage';
import BlogEditItemPage from '../pages/blog/BlogEditItemPage';
import StatsPage from '../pages/stats/StatsPage';

export default class Layout extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {

        return (
            <React.Fragment>
                <NavMenu />
                <Container>

                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/blog' element={<BlogPage />} />
                        <Route path='/blog/edit/:id' element={<BlogEditItemPage />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/reviews' element={<Reviews />} />
                        <Route path='/pricelist' element={<Pricelist />} />
                        <Route path='/contact' element={<Contact />} />
                        <Route path='/calendar' element={<VisitorCalendarPage />} />

                        {/*student*/}
                        <Route path='/student/payments' element={<StudentPaymentsPage />} />
                        <Route path='/student/calendar' element={<StudentCalendarPage />} />

                        {/*lector*/}
                        <Route path='/lector/payments' element={<LectorPaymentsPage />} />                        
                        <Route path='/lector/calendar' element={<LectorCalendarPage />} />
                        <Route path='/lector/stats' element={<StatsPage />} />

                        <Route path='/students' element={<StudentsPage />} />
                        <Route path='/student/:id' element={<StudentPage />} />
                        <Route path='/student/edit/:id' element={<StudentEditPage />} />

                        <Route path='/courses' element={<CoursesPage />} />
                        <Route path='/course/:id' element={<CoursePage />} />
                        <Route path='/course/edit/:id' element={<CourseEditPage />} />
                        <Route path='/course/:courseId/dedicatedtimes/edit' element={<DedicatedTimesEditPage />} />
                        <Route path='/course/:courseId/classroom/:id' element={<ClassroomPage />} />
                        <Route path='/course/:courseId/classroom/edit/:id' element={<ClassroomEditPage />} />
                        <Route path='/course/:courseId/classroom/:classroomId/lesson/:id' element={<LessonPage />} />
                        <Route path='/course/:courseId/classroom/:classroomId/lesson/edit/:id' element={<LessonEditPage />} />
                        
                        <Route path='/it' element={<InnerSiteTest />} />
                        <Route path='/admin/*' element={<AdminSettings />} />
                        <Route path='/lector' element={<LectorSettings />} />
                        <Route path='/student' element={<StudentSettings />} />
                        <Route path='/signin' element={<SignInPage />} />
                        <Route path='/signup' element={<SignUpPage />} />
                        <Route path='/settings/*' element={<Settings />} />
                        <Route path='/passwordRecovery' element={<PasswordRecoveryPage />} />
                        <Route path='/passwordchange' element={<PasswordChangePage />} />
                    </Routes>
                </Container>
            </React.Fragment>
        );
    }
}