import * as React from 'react';

const Reviews = () => (
    <div>
        <h1>Mluvangliciky.eu</h1>
        <br />
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum tincidunt orci id diam dignissim, nec cursus felis pellentesque. Nunc scelerisque diam ligula, in blandit augue cursus quis. Nulla lectus ex, rhoncus ac vulputate vitae, tempor gravida lectus. Sed tincidunt gravida quam, vel ullamcorper est vulputate eget. Praesent non aliquet magna. Ut sed maximus sem. Sed sit amet iaculis tortor.</p>
        <br/>
        <p>Etiam auctor non est quis consectetur. Donec a finibus orci. Mauris condimentum turpis sed felis tempor, vel finibus est egestas. Suspendisse ut viverra tortor. Cras id diam feugiat felis aliquam consectetur a sed turpis. Suspendisse consequat, mauris et pellentesque lobortis, nibh risus feugiat felis, sed egestas arcu massa eu lorem. Suspendisse et neque porta, pellentesque magna in, tincidunt urna. Pellentesque porta mollis dolor in feugiat. Quisque quam nibh, auctor eu ex varius, ultrices varius eros. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Morbi libero mi, iaculis vel hendrerit sed, iaculis vitae sapien.</p>
        <br />
        <p>Donec convallis nec nisl ac pharetra. Vestibulum ornare lectus nec velit sodales tempus. Donec quis velit in tortor pellentesque faucibus at ut tortor. Nunc arcu elit, volutpat nec sollicitudin volutpat, vestibulum a magna. Aliquam dictum orci non ex elementum, non congue dui elementum. In in enim in ligula pharetra venenatis ac vel felis. Mauris tincidunt ligula et mi pulvinar mattis. Vestibulum ornare elementum turpis a eleifend.</p>
        <br />
        <p>Vestibulum nec efficitur risus, at egestas tellus. Curabitur pulvinar nibh vel felis tincidunt, sed imperdiet sapien mattis. Donec id quam at nunc vestibulum dapibus sit amet at ipsum. Fusce malesuada enim quis elit placerat, sed faucibus libero lobortis. Vivamus porttitor laoreet arcu, elementum ornare dui faucibus placerat. Praesent odio lorem, placerat et odio et, mattis pellentesque nisi. Sed at porta nibh, vel aliquet arcu. Donec quis sodales ex. Donec dolor arcu, sodales vitae condimentum ut, consequat sed odio.</p>
        <br />
        <p>Integer scelerisque urna erat, vel bibendum ex lobortis eu. Duis venenatis quis odio malesuada sagittis. Suspendisse lacinia interdum sapien sit amet tristique. Mauris bibendum, neque at volutpat sagittis, orci nunc porttitor elit, sed elementum quam turpis id tellus. Nunc lobortis, lorem ac venenatis auctor, magna dui scelerisque nisl, aliquet sollicitudin leo lacus vel purus. Ut velit neque, bibendum quis arcu a, placerat tristique tortor. Mauris non nunc auctor, dapibus massa nec, iaculis ante. Nam pulvinar quam ac diam elementum vestibulum. Integer in mi pharetra, ullamcorper mauris eget, viverra sem. Sed pellentesque consequat metus. Nullam aliquet sapien tempus, hendrerit justo a, posuere est. Cras commodo, velit eget venenatis malesuada, lacus turpis lobortis leo, vitae dictum leo nisi sit amet nunc. In vel semper turpis, consequat congue nisi. Phasellus interdum erat mi, eu finibus justo sagittis vitae. Nulla facilisi.</p>
    </div>
);

export default Reviews;
