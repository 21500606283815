import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { classroomGet, lessonGetAllForClassroom, userGetAllStudentsForClassroom } from '../../services/ApiRequestService';
import { getDaysFromCron, getTimeFromCron } from '../../services/CronService';
import { TClassroom } from '../../types/TClassroom';
import { TLesson } from '../../types/TLesson';
import { TUser } from '../../types/TUser';

const ClassroomPage = () => {

    const { courseId, id } = useParams();
    if (id === undefined) {
        return (<p>No course</p>);
    }

    const [classroom, setClassroom] = useState<TClassroom>({} as TClassroom);
    const [students, setStudents] = useState<TUser[]>([]);
    const [lessons, setLessons] = useState<TLesson[]>([]);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        classroomGet(id).then((classroom) => {
            if (classroom !== null) {
                setClassroom(classroom);
            }
        });

        lessonGetAllForClassroom(id).then(
            (lessons) => {
                setLessons(lessons);
            }
        );

        userGetAllStudentsForClassroom(id).then(
            (students) => {
                setStudents(students)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const time = classroom.repeatCron ? getTimeFromCron(classroom.repeatCron) : "";

    let daysString = "";
    if (classroom.repeatCron) {
        const days = getDaysFromCron(classroom.repeatCron);

        if (days.includes(1)) {
            daysString += "Po, "
        }
        if (days.includes(2)) {
            daysString += "Út, "
        }
        if (days.includes(3)) {
            daysString += "St, "
        }
        if (days.includes(4)) {
            daysString += "Čt, "
        }
        if (days.includes(5)) {
            daysString += "Pá, "
        }
        if (days.includes(6)) {
            daysString += "So, "
        }
        if (days.includes(0)) {
            daysString += "Ne, "
        }

        daysString = daysString.substring(0, daysString.length - 2);
    };

    return (
        <div>
            <h1>Učebna</h1>
            <p>Název: {classroom.name}</p>
            <p>Popis: {classroom.description}</p>
            <p>Cena: {classroom.price}</p>
            <p>Délka: {classroom.duration}</p>
            <p>Čas: {time}</p>
            <p>Dny: {daysString}</p>

            <Link to={'/course/' + courseId + '/classroom/edit/' + id}><Button type='button'>Editovat</Button></Link>
            <Link to={'/course/' + courseId}><Button type='button'>Zpět</Button></Link>

            <h1>Studenti</h1>
            {students.map((student) => {
                return (
                    <React.Fragment key={student.id}>
                        <p>{student.email}
                            <Link to={'/student/' + student.id}>
                                <Button type='button'>Zobrazit</Button>
                            </Link>
                            <Link to={'/student/edit/' + student.id}>
                                <Button type='button'>Editovat</Button>
                            </Link>
                        </p>
                    </React.Fragment>)
            })}

            <h1>Lekce</h1>
            <Link to={'/course/' + courseId + '/classroom/' + id + '/lesson/edit/new'}>
                <Button type='button'>Nová lekce</Button>
            </Link>
            {lessons.map((lesson) => {
                return (
                    <React.Fragment key={lesson.id}>
                        <p>{lesson.name}
                            <Link to={'/course/' + courseId + '/classroom/' + id + '/lesson/' + lesson.id}>
                                <Button type='button'>Zobrazit</Button>
                            </Link>
                            <Link to={'/course/' + courseId + '/classroom/' + id + '/lesson/edit/' + lesson.id}>
                                <Button type='button'>Editovat</Button>
                            </Link>
                        </p>
                    </React.Fragment>)
            })}

        </div>
    )
};

export default ClassroomPage;