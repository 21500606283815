import * as React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { getAccessLevel } from '../../services/AccessService';
import { TAccessLevel } from '../../types/TAccessLevel';
import BaseInternalPage from '../BaseInternalPage';
import AdminSettings from './adminSettings/AdminSettings';
import LectorSettings from './lectorSettings/LectorSettings';
import StudentSettings from './studentSettings/StudentSettings';

export const SettingsPage = () => {
    var accessLeves = getAccessLevel();

    return (
        <BaseInternalPage>
            <h1>Nastavení</h1>
            <p>TODO: Test no access</p>
            <div>
                <ul>
                    {accessLeves.includes(TAccessLevel.ADMIN) ? <li><Link to='admin'>AdminSettings</Link></li> : null}
                    {accessLeves.includes(TAccessLevel.LECTOR) ? <li><Link to='lector'>LectorSettings</Link></li> : null}
                    {accessLeves.includes(TAccessLevel.STUDENT) ? <li><Link to='student'>StudentSettings</Link></li> : null}
                </ul>
            </div>
            <div>
                <Routes>
                    {accessLeves.includes(TAccessLevel.ADMIN) ? <Route path="admin/*" element={<AdminSettings />} /> : null}
                    {accessLeves.includes(TAccessLevel.LECTOR) ? <Route path="lector/*" element={<LectorSettings />} /> : null}
                    {accessLeves.includes(TAccessLevel.STUDENT) ? <Route path="student/*" element={<StudentSettings />} /> : null}
                </Routes>
            </div>
        </BaseInternalPage>
    );
};

export default SettingsPage
