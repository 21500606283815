import { Day } from "react-big-calendar";

export function createCron(days: number[], time: string): string {
    const daysString = days.sort().toString();
    const timeValues = time.split(':');
    const hours = timeValues[0];
    const minutes = timeValues[1];

    const cron = minutes + " " + hours + " * * " + daysString;

    return cron;
}

export function getDaysFromCron(cron: string): number[] {
    if (!cron)
        return [];

    const daysString = cron.split(' ')[4];

    const daysStringArray = daysString.split(',');

    const days = daysStringArray.map(d => Number(d));

    return days;
}

export function getTimeFromCron(cron: string): string {
    if (!cron)
        return '';

    const cronParts = cron.split(' ');
    const minutes = cronParts[0];
    const hours = cronParts[1];

    return hours + ":" + minutes;
}

export function getLastJob(cron: string): Date {

    const currentdate = new Date();

    const time = getTimeFromCron(cron);
    const timeParts = time.split(":");

    const cronHours = Number(timeParts[0]);
    const cronMinutes = Number(timeParts[1]);

    const usePrevDay = currentdate.getHours() * 60 + currentdate.getMinutes() < cronHours * 60 + cronMinutes;
    if (usePrevDay) {
        currentdate.setDate(currentdate.getDate() - 1);
    }

    const days = getDaysFromCron(cron);
    let finalDiff = 10;
    for (var i = 0; i < days.length; i++) {

        const cronDayOfWeek = days[i];

        let diff = (7 + currentdate.getDay() - cronDayOfWeek) % 7;

        if (finalDiff > diff)
            finalDiff = diff;
    }
    
    currentdate.setDate(currentdate.getDate() - finalDiff);

    currentdate.setHours(cronHours);
    currentdate.setMinutes(cronMinutes);

    return currentdate
}

export function getNextJob(cron: string): Date {

    const currentdate = new Date();

    const time = getTimeFromCron(cron);
    const timeParts = time.split(":");

    const cronHours = Number(timeParts[0]);
    const cronMinutes = Number(timeParts[1]);

    const useNextDay = currentdate.getHours() * 60 + currentdate.getMinutes() > cronHours * 60 + cronMinutes;
    if (useNextDay) {
        currentdate.setDate(currentdate.getDate() + 1);
    }

    const days = getDaysFromCron(cron);
    let finalDiff = 10;

    for (var i = 0; i < days.length; i++) {

        const cronDayOfWeek = days[i];

        let diff = (7 + cronDayOfWeek - currentdate.getDay()) % 7;

        if (finalDiff > diff)
            finalDiff = diff;
    }

    currentdate.setDate(currentdate.getDate() + finalDiff);

    currentdate.setHours(cronHours);
    currentdate.setMinutes(cronMinutes);

    return currentdate
}