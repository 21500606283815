import * as React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import BaseInternalPage from '../../BaseInternalPage';
import PersonalSettings from '../PersonalSettings';
import PersonalSettingsEdit from '../PersonalSettingsEdit';

const StudentSettings = () => {

    return (
        <BaseInternalPage>
            <div id="main">
                <div>
                    <h1>Student</h1>
                </div>
                <div>
                    <ul>
                        <li><Link to=''>Personal settings</Link></li>
                    </ul>
                </div>
                <div>
                    <Routes>
                        <Route path="/" element={<PersonalSettings />} />
                        <Route path="/edit" element={<PersonalSettingsEdit />} />
                    </Routes>
                </div>
            </div>
        </BaseInternalPage>
    );
};

export default StudentSettings