import * as React from 'react';
import shieldIcon from '../../Images/shield-2.svg';
import downIcon from '../../Images/chevrons-down.svg';
import './Pricelist.css';
import Header from '../../components/Header/Header';

const Pricelist = () => (
    <div>
        <Header />
        <p><img className="info-icon" src={shieldIcon} /> první lekce (60minut) zdarma</p>
        <div className="price-grid">
            <div className="price-box">
                <h2>Cestovatel</h2>
                <div className="price-inner-box">
                    <p>500,-</p>
                    <img className="detail-icon" src={downIcon} />
                </div>
            </div>
            <div className="price-box">
                <h2>Individuální hodiny</h2>
                <div className="price-inner-box">
                    <p>500,-</p>
                    <img className="detail-icon" src={downIcon} />
                </div>
            </div>
            <div className="price-box">
                <h2>Maturita</h2>
                <div className="price-inner-box">
                    <p>500,-</p>
                    <img className="detail-icon" src={downIcon} />
                </div>
            </div>
            <div className="price-box">
                <h2>English Exams</h2>
                <div className="price-inner-box">
                    <p>500,-</p>
                    <img className="detail-icon" src={downIcon} />
                </div>
            </div>
            <div className="price-box">
                <h2>Business English</h2>
                <div className="price-inner-box">
                    <p>500,-</p>
                    <img className="detail-icon" src={downIcon} />
                </div>
            </div>
            <div className="price-box">
                <h2>Státnice</h2>
                <div className="price-inner-box">
                    <p>500,-</p>
                    <img className="detail-icon" src={downIcon} />
                </div>
            </div>
        </div>
    </div>
);

export default Pricelist;
