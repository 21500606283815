import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { calendarGetNearForStudent } from '../../services/ApiRequestService';
import { TEvent } from '../../types/TEvent';

const StudentCalendarPage = () => {
    const [events, setEvents] = useState<TEvent[]>([]);

    const localizer = momentLocalizer(moment);

    useEffect(() => {
        calendarGetNearForStudent().then(
            (events) => {
                const newEvents = events.map((e) => {

                    const startTime = e.start !== undefined ? new Date(e.start) : new Date();
                    const endTime = e.end !== undefined ? new Date(e.end) : new Date();

                    const event: TEvent = {
                        start: startTime,
                        end: endTime,
                        title: e.title,
                        allDay: false
                    };

                    return event;
                });

                setEvents(newEvents);
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    //TODO: remove later
    const testEvent = [
        {
            allDay: false,
            title: 'My Event',
            start: new Date('2023-02-22T13:45:00-05:00'),
            end: new Date('2023-02-22T14:00:00-05:00')
        }
    ]

    return (
        <div>
            <h1>TODO</h1>
            <p>add list tab</p>
            <p>it should be possible to click to lesson and user</p>
            <p>show lessons by cron, show lessons created</p>

            <p>for lector show all his lessons, for student as well</p>


            <div className="myCustomHeight" style={{ height: 700 }}>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                />
            </div>

        </div>
    );
};

export default StudentCalendarPage;
