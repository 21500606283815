import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { sendInviteLink, statsGetUserStats, studentGet } from '../../services/ApiRequestService';
import { convertFromDateString, convertToDateString, convertToIsoString } from '../../services/DateService';
import { TSendInviteLinkRequest } from '../../types/TSendInviteLinkRequest';
import { TStudentStats } from '../../types/TStudentStats';
import { TUser } from '../../types/TUser';

const StudentPage = () => {

    let { id } = useParams();
    if (id === undefined) {
        return (<p>No user</p>);
    }

    const [student, setStudent] = useState<TUser>({} as TUser);
    const [studentStats, setStudentStats] = useState<TStudentStats>({} as TStudentStats);

    const [selectedDateFrom, setSelectedDateFrom] = useState<string>("");
    const [selectedDateTo, setSelectedDateTo] = useState<string>("");

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        studentGet(id).then((student) => {
            if (student !== null) {
                setStudent(student);
            }
        });

        const date = new Date();
        const dateStringTo = convertToDateString(date);
        setSelectedDateTo(dateStringTo);

        date.setDate(date.getDate() - 30);
        const dateStringFrom = convertToDateString(date);
        setSelectedDateFrom(dateStringFrom);

        RefreshStats(dateStringFrom, dateStringTo);

        statsGetUserStats(id, dateStringFrom, dateStringTo).then((studentStats) => {
            if (studentStats !== null) {
                setStudentStats(studentStats);
            }
        });
        return () => {
            //alert('Will unmount');
        };
    }, []);

    const sendInvite = async () => {
        const sendInviteLinkRequest: TSendInviteLinkRequest = {
            email: student.email
        };

        await sendInviteLink(sendInviteLinkRequest);

        alert("Odesláno");
    }

    const SelectDateFrom = (value: string) => {

        setSelectedDateFrom(value);
        RefreshStats(value, selectedDateTo);
    }

    const SelectDateTo = (value: string) => {

        setSelectedDateTo(value);
        RefreshStats(selectedDateFrom, value);
    }

    const RefreshStats = (dateStringFrom: string, dateStringTo: string) => {
        if (id === undefined) {
            return;
        }

        const dateFrom = convertFromDateString(dateStringFrom);
        const dateTo = convertFromDateString(dateStringTo);

        const isoStringFrom = convertToIsoString(dateFrom);
        const isoStringTo = convertToIsoString(dateTo);

        statsGetUserStats(id, isoStringFrom, isoStringTo).then((studentStats) => {
            if (studentStats !== null) {
                setStudentStats(studentStats);
            }
        });
    }

    return(
        <div>
            <h1>Student</h1>
            <p>Email: {student.email}</p>
            <p>Jméno: {student.firstName}</p>
            <p>Příjmení: {student.lastName}</p>

            <h1>Statistiky</h1>
            <p>Od: <input type="date" defaultValue={selectedDateFrom} onChange={(e) => SelectDateFrom(e.target.value)} /></p>
            <p>Do: <input type="date" defaultValue={selectedDateTo} onChange={(e) => SelectDateTo(e.target.value)} /></p>
            <p>Počet lekcí celkem: {studentStats.lessonCounts}</p>
            {studentStats.lessonCounts > 0 &&
                <React.Fragment>
                    <p>Počet lekcí účast: {studentStats.lessonAttendedCounts} ({studentStats.lessonAttendedCounts / studentStats.lessonCounts}%)</p>
                    <p>Počet lekcí zrušeno: {studentStats.lessonCancelledCounts} ({studentStats.lessonCancelledCounts / studentStats.lessonCounts}%)</p>
                    <p>Počet lekcí zrušeno pozdě: {studentStats.lessonLateCancelledCounts} ({studentStats.lessonLateCancelledCounts / studentStats.lessonCounts}%)</p>
                    <p>Počet lekcí zrušeno lektorem: {studentStats.lessonLectorCancelledCounts} ({studentStats.lessonLectorCancelledCounts / studentStats.lessonCounts}%)</p>
                </React.Fragment>
            }

            <p>Nedoplatek: {studentStats.notPayed}Kč</p>
            <p>Zaplaceno: {studentStats.payed}Kč</p>

            <Link to={'/student/edit/' + id}><Button type='button'>Editovat</Button></Link>
            <Link to='/students'><Button type='button'>Zpět</Button></Link>
            <Button type='button' onClick={sendInvite}>Send invite</Button>
        </div>)
};

export default StudentPage;
