import * as React from 'react';
import Header from '../../components/Header/Header';


const About = () => (
    <React.Fragment>
        <Header />
        <div className='block'>
            <div className='text'>
            <h1>Hello!</h1>
                <p>
                    Jmenuji se Klaudie Opletalová a
                    nabízím lekce anglického jazyka pro děti i
                    dospělé. Vyučuji zejména jazykové úrovně
                    A1 - C1, může se jednat o doučování školní
                    látky, přípravy na zkoušky, konverzační hodiny
                    nebo individuální lekce sestavené na míru.
                </p>                
            </div>
            <img src="https://www.w3schools.com/tags/img_girl.jpg" alt="Girl in a jacket" width="500" height="600"></img>
        </div>
        <div className='block'>
            <div className='text'>
                <h2>CERTIFIKÁTY A PRAXE</h2>
                <p>
                    Vlastním certifikát Cambridge 
                    English na úrovni C1 a novější
                    certifikát EF SET na úrovni C2,
                    individuální lekce angličtiny
                    poskytuji od roku 2020.
                </p>
            </div>
            <div>
                Image
            </div>
        </div>
        <div className='block'>
            <div>
                Info
            </div>
            <div className='text'>
                <h2>S ČÍM SE NA MĚ MŮŽETE OBRÁTIT?</h2>
                <p>
                    Pokud máte pocit, že školní výuka není pro Vaše dítě dostatečná, pokud
                    potřebujete zlepšit angličtinu z důvodu výjezdu do zahraničí, pracovního
                    pohovoru, zlepšení konverzace, nebo úspěšně zvládnout jakoukoliv zkoušku od
                    Cambridge English, maturitu, státnice, ...
                </p>
            </div>
        </div>
    </React.Fragment>
);

export default About;
