import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { studentAdd, studentDelete, studentGet, studentUpdate } from '../../services/ApiRequestService';
import { TUser } from '../../types/TUser';

const StudentEditPage = () => {

    let { id } = useParams();
    if (id === undefined) {
        return <p>No user</p>;
    }

    const [student, setStudent] = useState<TUser>({} as TUser);
    const navigate = useNavigate();

    if (id !== 'new') {
        React.useEffect(() => {
            if (id === undefined) {
                return;
            }

            studentGet(id).then((student) => {
                if (student !== null) {
                    setStudent(student);
                }
            });
            return () => {
                //alert('Will unmount');
            };
        }, []);
    }

    const Save = async () => {
        if (id === 'new') {
            //insert
            if (id !== undefined) {
                studentAdd(student).then((student) => {
                    if (student !== null) {
                        navigate("/student/" + student.id);
                    }
                    else {
                        navigate("/students");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                studentUpdate(student).then(() => {
                    navigate("/student/" + id);
                });
            }
        }
    }

    const Delete = async () => {
        if (confirm('Smazat uživatele?')) {
            if (id !== undefined) {
                studentDelete(id).then(() => {
                    navigate("/students");
                });
            }
        }
    }

    return (
        <div>
            <h1>Student</h1>

            <p>Email: <input type="text" value={student.email} onChange={(e) => setStudent({ ...student, email: e.target.value })} /></p>
            <p>Jméno: <input name="firstName" type="text" value={student.firstName} onChange={(e) => setStudent({ ...student, firstName: e.target.value })} /></p>
            <p>Příjmení: <input name="lastName" type="text" value={student.lastName} onChange={(e) => setStudent({ ...student, lastName: e.target.value })} /></p>

            <Button type='button' onClick={Save}>Uložit</Button>
            <Button type='button' onClick={Delete}>Smazat</Button>
            <Link to='/students'><Button type='button'>Zpět</Button></Link>

        </div>
    );
};

export default StudentEditPage;
