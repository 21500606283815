import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import BaseModal from '../../components/Modal/BaseModal';
import { classroomAdd, classroomDelete, classroomGet, classroomUpdate, courseGet, userGetAllStudents } from '../../services/ApiRequestService';
import { createCron, getDaysFromCron, getTimeFromCron } from '../../services/CronService';
import { TClassroom } from '../../types/TClassroom';
import { TUser } from '../../types/TUser';

const ClassroomEditPage = () => {

    let { courseId, id } = useParams();
    if (id === undefined) {
        return <p>No classroom</p>;
    }

    const [classroom, setClassroom] = useState<TClassroom>({} as TClassroom);
    const [students, setStudents] = useState<TUser[]>([]);

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [selectedStudents, setSelectedStudents] = useState<number[]>([]);

    const [selectedDays, setSelectedDays] = useState<boolean[]>([]);
    const [selectedTime, setSelectedTime] = useState<string>({} as string);


    const navigate = useNavigate();

    
    useEffect(() => {
        if (id === undefined || courseId === undefined) {
            return;
        }

        for (var i = 0; i < 7; i++) {
            selectedDays[i] = false;
        }
        setSelectedDays(selectedDays);

        if (id !== 'new') {
            classroomGet(id).then((classroom) => {
                if (classroom !== null) {

                    setSelectedTime(getTimeFromCron(classroom.repeatCron));

                    const days = getDaysFromCron(classroom.repeatCron);
                    for (var i = 0; i < days.length; i++) {
                        const selectedDay = days[i];
                        selectedDays[selectedDay] = true;
                    }
                    setSelectedDays(selectedDays);

                    setClassroom(classroom);
                    setSelectedStudents(classroom.studentIds);
                }
            });
        }
        else {
            courseGet(courseId).then((course) => {
                if (course !== null) {
                    classroom.price = course.price;
                    classroom.duration = 60;
                    setClassroom(classroom);
                }
            });
        }

        userGetAllStudents().then(
            (students) => {
                setStudents(students);
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const modalContent = (
        <div>
            <h1>Přidat studenta</h1>
            {students.map((student, i) => {
                return (
                    <div key={student.id}>
                        <p><input id={student.id.toString()} defaultChecked={selectedStudents.includes(student.id)} onChange={(e) => checkBoxStudentChanged(student.id, e.target.checked)}
                            type='checkbox'></input>{student.firstName + " " + student.lastName + " (" + student.email + ")"}</p>
                    </div>)
            })}
            <Button type='button' onClick={() => saveModal()}>Uložit</Button>
        </div>);

    const checkBoxStudentChanged = (id: number, checked: boolean) => {

        const index = selectedStudents.indexOf(id);
        if (checked) {
            if (index == -1) {
                selectedStudents.push(id);
            }
        }
        else {
            if (index > -1) {
                selectedStudents.splice(index, 1);
            }
        }
        setSelectedStudents(selectedStudents);
    };

    const saveModal = () => {
        setIsOpenModal(false);

        classroom.studentIds = selectedStudents;
        setClassroom(classroom);
    }

    const checkBoxDaysChanged = (index: number, value: boolean) => {

        selectedDays[index] = value;
        setSelectedDays(selectedDays);

        console.log(selectedDays);
    }

    const Save = async () => {

        const days: number[] = [];
        for (var i = 0; i < selectedDays.length; i++) {
            if (selectedDays[i])
                days.push(i);
        }

        if (days.length && selectedTime.length) {
            classroom.repeatCron = createCron(days, selectedTime);
            setClassroom(classroom);
        }

        if (id === 'new') {
            //insert
            if (id !== undefined && courseId !== undefined) {
                classroom.courseId = parseInt(courseId);

                console.log(classroom);

                classroomAdd(classroom).then((classroom) => {
                    if (classroom !== null) {
                        navigate("/course/" + courseId + "/classroom/" + classroom.id);
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                classroomUpdate(classroom).then(() => {
                    navigate("/course/" + courseId + "/classroom/" + id);
                });
            }
        }
    }

    const Delete = async () => {
        if (confirm('Smazat kurz?')) {
            if (id !== undefined) {
                classroomDelete(id).then(() => {
                    navigate("/course/" + courseId);
                });
            }
        }
    }

    return (
        <div>
            <h1>Editace učebny</h1>
            <p>Název: <input type="text" defaultValue={classroom.name} onChange={(e) => setClassroom({ ...classroom, name: e.target.value })} /></p>
            <p>Popis: <input type="text" defaultValue={classroom.description} onChange={(e) => setClassroom({ ...classroom, description: e.target.value })} /></p>
            <p>Poznámky: <input type="text" defaultValue={classroom.notes} onChange={(e) => setClassroom({ ...classroom, notes: e.target.value })} /></p>
            <p>Cena: <input type="number" defaultValue={classroom.price} step={50} onChange={(e) => setClassroom({ ...classroom, price: Number(e.target.value) })} /></p>
            <p>Délka: <input type="number" defaultValue={classroom.duration} step={15} onChange={(e) => setClassroom({ ...classroom, duration: Number(e.target.value) })} /></p>

            <p>Start:</p>
            <p>
                <input id="po" type='checkbox' defaultChecked={selectedDays[1]} onClick={(e) => checkBoxDaysChanged(1, e.currentTarget.checked)} /><label htmlFor="po">Po</label>
                <input id="ut" type='checkbox' defaultChecked={selectedDays[2]} onClick={(e) => checkBoxDaysChanged(2, e.currentTarget.checked)} /><label htmlFor="ut">Út</label>
                <input id="st" type='checkbox' defaultChecked={selectedDays[3]} onClick={(e) => checkBoxDaysChanged(3, e.currentTarget.checked)} /><label htmlFor="st">St</label>
                <input id="ct" type='checkbox' defaultChecked={selectedDays[4]} onClick={(e) => checkBoxDaysChanged(4, e.currentTarget.checked)} /><label htmlFor="ct">Čt</label>
                <input id="pa" type='checkbox' defaultChecked={selectedDays[5]} onClick={(e) => checkBoxDaysChanged(5, e.currentTarget.checked)} /><label htmlFor="pa">Pá</label>
                <input id="so" type='checkbox' defaultChecked={selectedDays[6]} onClick={(e) => checkBoxDaysChanged(6, e.currentTarget.checked)} /><label htmlFor="so">So</label>
                <input id="ne" type='checkbox' defaultChecked={selectedDays[0]} onClick={(e) => checkBoxDaysChanged(0, e.currentTarget.checked)} /><label htmlFor="ne">Ne</label>
            </p>
            <p><input type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} /></p>

            <h1>Studenti</h1>
            {students.map((student) => {
                const index = selectedStudents.indexOf(student.id);
                if (index > -1) {
                    return (
                        <React.Fragment key={student.id}>
                            <p>{student.email}
                                <Link to={'/student/' + student.id}>
                                    <Button type='button'>Zobrazit</Button>
                                </Link>
                                <Link to={'/student/edit/' + student.id}>
                                    <Button type='button'>Editovat</Button>
                                </Link>
                            </p>
                        </React.Fragment>)
                }
            })}
            <Button type='button' onClick={() => setIsOpenModal(true)}>Přidat studenta</Button>
            <br />

            <Button type='button' onClick={Save}>Uložit</Button>
            <Button type='button' onClick={Delete}>Smazat</Button>
            <Link to={"/course/" + courseId + "/classroom/" + id}><Button type='button'>Zpět</Button></Link>

            {isOpenModal && <BaseModal setIsOpen={setIsOpenModal} content={modalContent} />}
        </div>
    )
};

export default ClassroomEditPage;