import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { studentGetAll } from '../../services/ApiRequestService';
import { TUser } from '../../types/TUser';

const StudentsPage = () => {
    const [students, setStudents] = useState<TUser[]>([]);

    React.useEffect(() => {

        studentGetAll().then(
            (students) => {
                setStudents(students)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <div>
            <h1>Studenti</h1>
            {students.map((student) => {
                return (
                    <React.Fragment key={student.id}>
                        <p>{student.firstName + " " + student.lastName + " (" + student.email + ")"}
                            <Link to={'/student/' + student.id}>
                                <Button type='button'>Zobrazit</Button>
                            </Link>
                            <Link to={'/student/edit/' + student.id}>
                                <Button type='button'>Editovat</Button>
                            </Link>
                        </p>
                    </React.Fragment>)
            })}

            <Link to='/student/edit/new'>
                <Button type='button'>Nový student</Button>
            </Link>
        </div>
    )
};

export default StudentsPage;
