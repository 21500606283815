import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { statsGet } from '../../services/ApiRequestService';
import { convertToDateString } from '../../services/DateService';
import { TStats } from '../../types/TStats';

const StatsPage = () => {

    const [selectedFromDate, setSelectedFromDate] = useState<string>("");
    const [selectedToDate, setSelectedToDate] = useState<string>("");
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const [statResults, setStatResults] = useState<TStats[]>([]);

    useEffect(() => {
        var date = new Date();
        date.setDate(date.getDate() - 30);
        setSelectedFromDate(date.toString());
        setSelectedToDate(new Date().getDate().toString());

        const today = new Date();
        today.setDate(today.getDate() - 30);
        var fromDate = convertToDateString(today);
        setSelectedFromDate(fromDate);

        const toDate = convertToDateString(new Date());
        setSelectedToDate(toDate);

        debugger;

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const Show = async () => {
        statsGet(selectedFromDate, selectedToDate, selectedUserIds).then(
            (stats) => {
                setStatResults(stats)
            }
        );
    }

    return (
        <React.Fragment>
            <p>Od: <input type="date" defaultValue={selectedFromDate} onChange={(e) => setSelectedFromDate(e.target.value)} /></p>
            <p>Do: <input type="date" defaultValue={selectedToDate} onChange={(e) => setSelectedToDate(e.target.value)} /></p>
            <Button type='button' onClick={Show}>Zobrazit</Button>

            {statResults.map((stat, i) => {
                return (
                    <React.Fragment key={i}>
                        <p>
                            {stat.key} - {stat.value}
                        </p>
                    </React.Fragment>)
            })}

        </React.Fragment>
    )
};

export default StatsPage;
