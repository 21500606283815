import * as React from 'react';
import './Header.css';

const Header = () => (
    <div>
        <h1 className="container-header">
            <span className="container-header-black">Mluv</span>
            <span className="container-header-red">Anglicky</span>
            <span className="container-header-black">.eu</span>
        </h1>
    </div>
);

export default Header;
