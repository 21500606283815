import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import './BaseModal.css';

const BaseModal = ({ setIsOpen, content }: any) => {
    //const [emergencyGuidelinesModalProps, emergencyGuidelinesModal] = useModal();

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={() => setIsOpen(false)}>&times;</span>
                {content}
            </div>
        </div>
    );
};

export default BaseModal