import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import BaseModal from '../../components/Modal/BaseModal';
import { dedicatedTimeAdd, dedicatedTimeDelete, dedicatedTimeGetAllForCourse, dedicatedTimeUpdate } from '../../services/ApiRequestService';
import { convertFromIsoString, convertFromTimeString, convertToIsoString, convertToTimeString } from '../../services/DateService';
import { TDedicatedTime } from '../../types/TDedicatedTime';

const DedicatedTimesEditPage = () => {

    const { courseId } = useParams();

    const [dedicatedTimes, setDedicatedTimes] = useState<TDedicatedTime[]>([]);
        
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [dedicatedTime, setDedicatedTime] = useState<TDedicatedTime>({} as TDedicatedTime);
    const [startTime, setStartTime] = useState<string>("00:00");
    const [endTime, setEndTime] = useState<string>("00:00");

    useEffect(() => {

        if (courseId === undefined) {
            return;
        }

        dedicatedTimeGetAllForCourse(courseId).then(
            (dedicatedTimes) => {                
                setDedicatedTimes(dedicatedTimes)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    const modalContent = (
        <div>
            <h1>Upravit čas</h1>
            <p>Start: <input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} /></p>
            <p>Konec: <input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} /></p>

            <Button type='button' onClick={() => deleteModal()}>Smazat</Button>
            <Button type='button' onClick={() => saveModal()}>Uložit</Button>
        </div>);

    const openModal = (dayOfWeek: number, dedicatedTimeId: number | null) => {

        if (courseId === undefined) {
            return;
        }

        dedicatedTime.dayOfWeek = dayOfWeek;
        dedicatedTime.id = dedicatedTimeId === null ? -1 : dedicatedTimeId;

        if (dedicatedTimeId === null) {
            dedicatedTime.courseId = parseInt(courseId);

            setStartTime("00:00");
            setEndTime("00:00");
            setDedicatedTime(dedicatedTime);
        }
        else {            
            const at = dedicatedTimes.find(a => a.id == dedicatedTimeId);

            //TODO: find better logic
            if (at === undefined)
                return;

            debugger;

            const startDate = convertFromIsoString(at.startTime);
            const startString = convertToTimeString(startDate);
            setStartTime(startString);

            const endDate = convertFromIsoString(at.endTime);
            const endString = convertToTimeString(endDate);
            setEndTime(endString);

            setDedicatedTime(at);
        }
        
        setIsOpenModal(true);
    }

    const saveModal = () => {
        setIsOpenModal(false);

        if (courseId === undefined) {
            return;
        }

        dedicatedTime.courseId = parseInt(courseId);

        const startDate = convertFromTimeString(startTime);
        dedicatedTime.startTime = convertToIsoString(startDate);

        const endDate = convertFromTimeString(endTime);
        dedicatedTime.endTime = convertToIsoString(endDate);

        setDedicatedTime(dedicatedTime);

        if (dedicatedTime.id > 0) {
            dedicatedTimeUpdate(dedicatedTime).then(() => {
                //refresh
                //TODO: maybe find something better
                window.location.reload();
            });
        }
        else {
            dedicatedTimeAdd(dedicatedTime).then(() => {
                //refresh
                //TODO: maybe find something better
                window.location.reload();
            });            
        }        
    }

    const deleteModal = () => {
        if (confirm('Smazat?')) {

            if (dedicatedTime.id !== undefined) {
                dedicatedTimeDelete(dedicatedTime.id.toString()).then(() => {

                    //TODO: maybe find something better
                    window.location.reload();
                });
            }
        }
    }

    const getTimeRange = (at: TDedicatedTime): string => {

        if (!at.startTime)
            return "";

        const startDate = convertFromIsoString(at.startTime);
        const startTime = convertToTimeString(startDate);

        const endDate = convertFromIsoString(at.endTime);
        const endTime = convertToTimeString(endDate);

        return startTime + "-" + endTime;
    }

    const mondays = dedicatedTimes.filter(at => at.dayOfWeek === 1).sort();
    const tuesdays = dedicatedTimes.filter(at => at.dayOfWeek === 2).sort();
    const wednesdays = dedicatedTimes.filter(at => at.dayOfWeek === 3).sort();
    const thursdays = dedicatedTimes.filter(at => at.dayOfWeek === 4).sort();
    const fridays = dedicatedTimes.filter(at => at.dayOfWeek === 5).sort();
    const saturdays = dedicatedTimes.filter(at => at.dayOfWeek === 6).sort();
    const sundays = dedicatedTimes.filter(at => at.dayOfWeek === 0).sort();


    return (
        <div>
            <h1>Volné hodiny</h1>
            {/*//TODO: change styles*/}
            <table width={1100} style={{ tableLayout: 'fixed' }} >
                <thead>
                    <tr>
                        <th>Po</th>
                        <th>Út</th>
                        <th>St</th>
                        <th>Čt</th>
                        <th>Pá</th>
                        <th>So</th>
                        <th>Ne</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {mondays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (
                                    <React.Fragment key={i}>
                                        <Button type='button' onClick={() => openModal(1, at.id)}>{timeRange} <br /> Upravit</Button>
                                    </React.Fragment>)
                        })}
                        </td>
                        <td>
                        {tuesdays.map((at, i) => {
                            const timeRange = getTimeRange(at);
                            return (
                                <React.Fragment key={i}>
                                    <Button type='button' onClick={() => openModal(1, at.id)}>{timeRange} <br /> Upravit</Button>
                                </React.Fragment>)
                        })}
                        </td>
                        <td>
                        {wednesdays.map((at, i) => {
                            const timeRange = getTimeRange(at);
                            return (
                                <React.Fragment key={i}>
                                    <Button type='button' onClick={() => openModal(1, at.id)}>{timeRange} <br /> Upravit</Button>
                                </React.Fragment>)
                        })}
                        </td>
                        <td>
                        {thursdays.map((at, i) => {
                            const timeRange = getTimeRange(at);
                            return (
                                <React.Fragment key={i}>
                                    <Button type='button' onClick={() => openModal(1, at.id)}>{timeRange} <br /> Upravit</Button>
                                </React.Fragment>)
                        })}
                        </td>
                        <td>
                        {fridays.map((at, i) => {
                            const timeRange = getTimeRange(at);
                            return (
                                <React.Fragment key={i}>
                                    <Button type='button' onClick={() => openModal(1, at.id)}>{timeRange} <br /> Upravit</Button>
                                </React.Fragment>)
                        })}
                        </td>
                        <td>
                        {saturdays.map((at, i) => {
                            const timeRange = getTimeRange(at);
                            return (
                                <React.Fragment key={i}>
                                    <Button type='button' onClick={() => openModal(1, at.id)}>{timeRange} <br /> Upravit</Button>
                                </React.Fragment>)
                        })}
                        </td>
                        <td>
                        {sundays.map((at, i) => {
                            const timeRange = getTimeRange(at);
                            return (
                                <React.Fragment key={i}>
                                    <Button type='button' onClick={() => openModal(1, at.id)}>{timeRange} <br /> Upravit</Button>
                                </React.Fragment>)
                        })}
                        </td>
                    </tr>

                    <tr>
                        <td><Button type='button' onClick={() => openModal(1, null)}>Přidat</Button></td>
                        <td><Button type='button' onClick={() => openModal(2, null)}>Přidat</Button></td>
                        <td><Button type='button' onClick={() => openModal(3, null)}>Přidat</Button></td>
                        <td><Button type='button' onClick={() => openModal(4, null)}>Přidat</Button></td>
                        <td><Button type='button' onClick={() => openModal(5, null)}>Přidat</Button></td>
                        <td><Button type='button' onClick={() => openModal(6, null)}>Přidat</Button></td>
                        <td><Button type='button' onClick={() => openModal(0, null)}>Přidat</Button></td>
                    </tr>
                </tbody>
            </table>

            {isOpenModal && <BaseModal setIsOpen={setIsOpenModal} content={modalContent} />}
        </div>
    )
};

export default DedicatedTimesEditPage;