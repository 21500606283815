import * as React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import BaseInternalPage from '../../BaseInternalPage';
import PersonalSettings from '../PersonalSettings';
import TodoList from './TodoList';
import User from './User';
import UserEdit from './UserEdit';
import Users from './Users';

export const AdminSettings = () => {

    return (
        <BaseInternalPage>
            <div id="main">
                <div>
                    <h1>Admin</h1>
                    <p>TODO</p>
                    <br />
                </div>
                <div>
                    <ul>
                        <li><Link to=''>Personal settings</Link></li>
                        <li><Link to='site'>Site settings</Link></li>
                        <li><Link to='users'>List of users</Link></li>
                        <li><Link to='courses'>List of courses</Link></li>
                        <li><Link to='lessons'>List of lessons</Link></li>
                        <li><Link to='todo'>TODO</Link></li>
                    </ul>
                </div>
                <div>
                    <Routes>
                        <Route path="/" element={<PersonalSettings />} />
                        <Route path="users" element={<Users />} />                        
                        <Route path="user/:id" element={<User />} />
                        <Route path="user/edit/:id" element={<UserEdit />} />

                        <Route path="todo" element={<TodoList />} />

                    </Routes>
                </div>
            </div>
        </BaseInternalPage>
    );
};

export default AdminSettings
