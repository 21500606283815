import * as React from 'react';
import Header from '../components/Header/Header';

const Home = () => (
    <div>
        <Header />


        <h1>Hey! Hi! Hello!</h1>

        <p>Nabízím lekce anglického jazyka pro děti i dospělé. Vyučuji zejména jazykové úrovně A1 - B2, může se jednat o doučování školní látky, přípravy na zkoušky, ryze konverzační hodiny, nebo individuální lekce sestavené na míru.</p>
        <br/>
        <p>Často k výuce používám materiály od Cambridge English, Umíme anglicky, Oxford Learner's Dictionaries, Natter & Ramble, The Guardian a spousty dalších.</p>
        <br />
        <p>Výuku realizuji vzhledem k aktuální epidemiologické situaci pouze online, standardně za použití Skype, Google meets, nebo Microsoft Teams.</p>
    </div>
);

export default Home;
