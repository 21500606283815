import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getAccessLevel } from '../../services/AccessService';
import { lessonAdd, lessonDelete, lessonGet, lessonUpdate, classroomGet } from '../../services/ApiRequestService';
import { getNextJob, getTimeFromCron } from '../../services/CronService';
import { convertToDateString } from '../../services/DateService';
import { TAccessLevel } from '../../types/TAccessLevel';
import { TClassroom } from '../../types/TClassroom';
import { TLesson } from '../../types/TLesson';

const LessonEditPage = () => {

    var accessLevels = getAccessLevel();
    if (!accessLevels.includes(TAccessLevel.LECTOR) && !accessLevels.includes(TAccessLevel.STUDENT)) {
        return (<p>Invalid role</p>)
    }

    let { courseId, classroomId, id } = useParams();

    if (id === undefined) {
        return (<p>No lesson</p>);
    }

    const [lesson, setLesson] = useState<TLesson>({} as TLesson);
    const [classroom, setClassroom] = useState<TClassroom>({} as TClassroom);

    const [selectedDate, setSelectedDate] = useState<string>("");
    const [selectedTime, setSelectedTime] = useState<string>("5:00");

    const navigate = useNavigate();

    useEffect(() => {
        if (id === undefined || classroomId === undefined) {
            return;
        }

        //set defaults
        const today = new Date();
        today.setDate(today.getDate() + 1);
        var dateString = convertToDateString(today);

        setSelectedDate(dateString);

        if (id !== "new") {
            lessonGet(id).then((lesson) => {
                if (lesson !== null) {
                    setLesson(lesson);

                    setSelectedDate(lesson.start.substring(0, 10));
                    setSelectedTime(lesson.start.substring(11, 16));
                } 
            });
        }
        else {
            classroomGet(classroomId).then((classroom) => {
                if (classroom !== null) {

                    lesson.price = classroom.price;
                    lesson.duration = classroom.duration;
                    setSelectedTime(getTimeFromCron(classroom.repeatCron));

                    const latesLesson = getNextJob(classroom.repeatCron);
                    setSelectedDate(convertToDateString(latesLesson));

                    setLesson(lesson);
                    setClassroom(classroom);
                }
            });
        }
    
        return () => {
            //alert('Will unmount');
        };
    }, []);

    const Save = async () => {
        lesson.start = selectedDate + "T" + selectedTime.padStart(5, '0');
        if (!lesson.name) {
            lesson.name = classroom.name + "-" + selectedDate;
        }

        setLesson(lesson);

        if (id === 'new') {
            //insert
            if (id !== undefined && classroomId !== undefined) {

                lesson.classroomId = parseInt(classroomId);

                lessonAdd(lesson).then((lesson) => {
                    if (lesson !== null) {
                        navigate("/course/" + courseId + "/classroom/" + classroomId + "/lesson/" + lesson.id);
                    }
                    else {
                        navigate("/course/" + courseId + "/classroom/" + classroomId + "/lesson");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                lessonUpdate(lesson).then(() => {
                    navigate("/course/" + courseId + "/classroom/" + classroomId + "/lesson/" + id);
                });
            }
        }
    }

    const Delete = async () => {
        if (confirm('Smazat lekci?')) {
            if (id !== undefined) {
                lessonDelete(id).then(() => {
                    navigate("/course/" + courseId + "/classroom/" + classroomId);
                });
            }
        }
    }

    return (
        <React.Fragment>
            <h1>Editace lekce</h1>

            <p>Název: <input type="text" placeholder={classroom.name + "-" + selectedDate} defaultValue={lesson.name} onChange={(e) => setLesson({ ...lesson, name: e.target.value })} /></p>
            
            <p>Popis: <textarea defaultValue={lesson.description} onChange={(e) => setLesson({ ...lesson, description: e.target.value })} />
            </p>

            <p>Datum: <input type="date" defaultValue={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} /></p>
            <p>Čas: <input type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} /></p>
            <p>Délka: <input type="number" defaultValue={lesson.duration} step={15} onChange={(e) => setLesson({ ...lesson, duration: Number(e.target.value) })} /></p>

            <p>Cena: <input type="number" defaultValue={lesson.price} step={50} onChange={(e) => setLesson({ ...lesson, price: Number(e.target.value) })} /></p>

            <p>Placeno: <input type="checkbox" defaultChecked={lesson.payed} onClick={(e) => setLesson({ ...lesson, payed: e.currentTarget.checked })} /></p>
            <p>Online: <input type="checkbox" defaultChecked={lesson.isOnline} onClick={(e) => setLesson({ ...lesson, isOnline: e.currentTarget.checked })} /></p>
            <p>Zkušební: <input type="checkbox" defaultChecked={lesson.isTrial} onClick={(e) => setLesson({ ...lesson, isTrial: e.currentTarget.checked })} /></p>

            <p>Status lekce:
                    <select value={lesson.state} onChange={(e) => setLesson({ ...lesson, state: Number(e.target.value) })}>
                    <option value="0">Ještě neproběhla</option>
                    <option value="1">Účast</option>
                    <option value="2">Zrušeno</option>
                    <option value="3">Zrušeno pozdě</option>
                    <option value="4">Zrušeno lektorem</option>
                </select>
            </p>

            <Button type='button' onClick={Save}>Uložit</Button>
            <Button type='button' onClick={Delete}>Smazat</Button>
            <Link to={"/course/" + courseId + "/classroom/" + classroomId}><Button type='button'>Zpět</Button></Link>

        </React.Fragment>
    )
};

export default LessonEditPage;
