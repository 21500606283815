import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getAccessLevel } from '../../services/AccessService';
import { courseGetAllForLector } from '../../services/ApiRequestService';
import { TAccessLevel } from '../../types/TAccessLevel';
import { TCourse } from '../../types/TCourse';

const CoursesPage = () => {

    const [courses, setCourses] = useState<TCourse[]>([]);

    const accessLeves = getAccessLevel();

    useEffect(() => {
        courseGetAllForLector().then(
            (courses) => {
                setCourses(courses)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <div>
            <h1>Kurzy</h1>
            {courses.map((course, i) => {
                return (
                    <React.Fragment key={course.id}>
                        <p>{course.name} {course.language}
                            <Link to={'/course/' + course.id}>
                                <Button type='button'>Zobrazit</Button>
                            </Link>
                            {accessLeves.includes(TAccessLevel.LECTOR) &&
                                <Link to={'/course/edit/' + course.id}>
                                    <Button type='button'>Editovat</Button>
                                </Link>
                            }
                        </p>
                    </React.Fragment>)
            })}

            {accessLeves.includes(TAccessLevel.LECTOR) &&
                <Link to='/course/edit/new'>
                    <Button type='button'>Nový kurz</Button>
                </Link>
            }
        </div>
    )
};

export default CoursesPage;