import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getAccessLevel } from '../../services/AccessService';
import { dedicatedTimeGetAllForCourse, courseGet, classroomGetAllForCourse } from '../../services/ApiRequestService';
import { convertFromIsoString, convertToTimeString } from '../../services/DateService';
import { TAccessLevel } from '../../types/TAccessLevel';
import { TDedicatedTime } from '../../types/TDedicatedTime';
import { TCourse } from '../../types/TCourse';
import { TClassroom } from '../../types/TClassroom';

const CoursePage = () => {

    var accessLeves = getAccessLevel();

    const { id } = useParams();
    if (id === undefined) {
        return (<p>No course</p>);
    }

    const [course, setCourse] = useState<TCourse>({} as TCourse);
    const [classrooms, setClassrooms] = useState<TClassroom[]>([]);
    const [dedicatedTimes, setDedicatedTimes] = useState<TDedicatedTime[]>([]);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        courseGet(id).then((course) => {
            if (course !== null) {
                setCourse(course);
            }
        });

        classroomGetAllForCourse(id).then(
            (classroom) => {
                setClassrooms(classroom)
            }
        );

        dedicatedTimeGetAllForCourse(id).then(
            (dedicatedTimes) => {
                setDedicatedTimes(dedicatedTimes)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    //TODO: maybe find better solution
    const mondays = dedicatedTimes.filter(at => at.dayOfWeek === 1).sort();
    const tuesdays = dedicatedTimes.filter(at => at.dayOfWeek === 2).sort();
    const wednesdays = dedicatedTimes.filter(at => at.dayOfWeek === 3).sort();
    const thursdays = dedicatedTimes.filter(at => at.dayOfWeek === 4).sort();
    const fridays = dedicatedTimes.filter(at => at.dayOfWeek === 5).sort();
    const saturdays = dedicatedTimes.filter(at => at.dayOfWeek === 6).sort();
    const sundays = dedicatedTimes.filter(at => at.dayOfWeek === 0).sort();

    //TODO: duplicate
    const getTimeRange = (at: TDedicatedTime): string => {

        if (!at.startTime)
            return "";

        const startDate = convertFromIsoString(at.startTime);
        const startTime = convertToTimeString(startDate);

        const endDate = convertFromIsoString(at.endTime);
        const endTime = convertToTimeString(endDate);

        return startTime + "-" + endTime;
    }
    
    return (
        <div>
            <h1>Kurz</h1>

            <p>Email: {course.name}</p>
            <p>Popis: {course.description}</p>
            <p>Jazyk: {course.language}</p>
            <p>Cena: {course.price}</p>            

            {accessLeves.includes(TAccessLevel.LECTOR) &&
                <Link to={'/course/edit/' + id}><Button type='button'>Editovat</Button></Link>
            }
            <Link to='/courses'><Button type='button'>Zpět</Button></Link>

            <h1>Vyhrazené hodiny</h1>

            {/*//TODO: change styles*/}
            <table width={1100} style={{ tableLayout: 'fixed' }}>
                <thead>
                    <tr>
                        <th>Po</th>
                        <th>Út</th>
                        <th>St</th>
                        <th>Čt</th>
                        <th>Pá</th>
                        <th>So</th>
                        <th>Ne</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {mondays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (<React.Fragment key={i}>{timeRange}<br /></React.Fragment>)
                            })}
                        </td>
                        <td>
                            {tuesdays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (<React.Fragment key={i}>{timeRange}<br /></React.Fragment>)
                            })}
                        </td>
                        <td>
                            {wednesdays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (<React.Fragment key={i}>{timeRange}<br /></React.Fragment>)
                            })}
                        </td>
                        <td>
                            {thursdays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (<React.Fragment key={i}>{timeRange}<br /></React.Fragment>)
                            })}
                        </td>
                        <td>
                            {fridays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (<React.Fragment key={i}>{timeRange}<br /></React.Fragment>)
                            })}
                        </td>
                        <td>
                            {saturdays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (<React.Fragment key={i}>{timeRange}<br /></React.Fragment>)
                            })}
                        </td>
                        <td>
                            {sundays.map((at, i) => {
                                const timeRange = getTimeRange(at);
                                return (<React.Fragment key={i}>{timeRange}<br /></React.Fragment>)
                            })}
                        </td>
                    </tr>
                </tbody>
            </table>
            {accessLeves.includes(TAccessLevel.LECTOR) &&
                <Link to={'/course/' + id + '/dedicatedTimes/edit'}>
                    <Button type='button'>Upravit</Button>
                </Link>
            }

            <h1>Učebna</h1>
            <Link to={'/course/' + id + '/classroom/edit/new'}>
                <Button type='button'>Nová učebna</Button>
            </Link>
            {classrooms.map((classroom) => {
                return (
                    <React.Fragment key={classroom.id}>
                        <p>{classroom.name}
                            <Link to={'/course/' + id + '/classroom/' + classroom.id}>
                                <Button type='button'>Zobrazit</Button>
                            </Link>
                            {accessLeves.includes(TAccessLevel.LECTOR) &&
                                <Link to={'/course/' + id + '/classroom/edit/' + classroom.id}>
                                    <Button type='button'>Editovat</Button>
                                </Link>
                            }
                        </p>
                    </React.Fragment>)
            })}
        </div>
    )
};

export default CoursePage;