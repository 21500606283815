import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { calendarGetNearForLector } from '../../services/ApiRequestService';
import { TEvent } from '../../types/TEvent';

const LectorCalendarPage = () => {
    const [events, setEvents] = useState<TEvent[]>([]);

    const localizer = momentLocalizer(moment);

    useEffect(() => {
        calendarGetNearForLector().then(
            (events) => {
                const newEvents = events.map((e) => {

                    const startTime = e.start !== undefined ? new Date(e.start) : new Date();
                    const endTime = e.end !== undefined ? new Date(e.end) : new Date();

                    const event: TEvent = {
                        start: startTime,
                        end: endTime,
                        title: e.title,
                        allDay: false
                    };

                    return event;
                });

                setEvents(newEvents);
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    //TODO: remove later
    const testBgEvents = [
        {
            allDay: false,
            title: 'My Event',
            start: new Date('2023-03-10T18:00:00+01:00'),
            end: new Date('2023-03-10T20:00:00+01:00')
        }
    ]

    const CustomTimeSlotWrapper = ({ value, resource, children }: any) => {

        debugger;

        console.log(value);
        console.log(resource);
        console.log(children);

        // convert your `value` (a Date object) to something displayable
        return (
            <div className="custom-slot-container">
                {/*<span className="hidden-value">"abc"</span>*/}
            </div>
        );
    }

    //example how to select slot manualy
    const handleSelectSlot = useCallback(({ start, end }: any) => {

            //const title = window.prompt('New Event Name')
            //if (title) {
            //    setEvents((prev) => [...prev, { start, end, title }])
            //}
        },
        [setEvents]
    )

    const handleSelectEvent = useCallback((event: any) => {
        console.log("handleSelectEvent:" + event );
        window.alert(event.title)
    },
        []
    )
    

    return (
        <div>
            <h1>TODO</h1>
            <p>it should be possible to click to lesson and user</p>
            <p>show lessons by cron, show lessons created</p>

            <p>for lector show all his lessons, for student as well</p>


            <div className="myCustomHeight" style={{ height: 700 }}>
                <Calendar
                    backgroundEvents={testBgEvents }
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"

                    onSelectEvent={handleSelectEvent}
                    onSelectSlot={handleSelectSlot}
                    selectable
                />
            </div>

            {/*<div className="myCustomHeight" style={{ height: 700 }}>*/}
            {/*    <Calendar*/}
            {/*        components={{*/}
            {/*            timeSlotWrapper: CustomTimeSlotWrapper*/}
            {/*        }}*/}
            {/*        backgroundEvents={testEvent}*/}
            {/*        localizer={localizer}*/}
            {/*        events={events}*/}
            {/*        startAccessor="start"*/}
            {/*        endAccessor="end"*/}
            {/*    />*/}
            {/*</div>*/}


        </div>
    );
};

export default LectorCalendarPage;
