import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { blogAdd, blogDelete, blogGet, blogUpdate } from '../../services/ApiRequestService';
import { TBlog } from '../../types/TBlog';


const BlogEditItemPage = () => {

    let { id } = useParams();
    if (id === undefined) {
        return <p>No blog</p>;
    }

    const [blog, setBlog] = useState<TBlog>({} as TBlog);
    const navigate = useNavigate();


    if (id !== 'new') {
        useEffect(() => {
            if (id === undefined) {
                return;
            }

            blogGet(id).then((blog) => {
                if (blog !== null) {
                    setBlog(blog);
                }
            });
            return () => {
                //alert('Will unmount');
            };
        }, []);
    }

    const Save = async () => {
        if (id === 'new') {
            //insert
            if (id !== undefined) {
                blogAdd(blog).then((blog) => {
                    if (blog !== null) {
                        navigate("/blog/edit/" + blog.id);
                    }
                    else {
                        navigate("/blog");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                blogUpdate(blog).then(() => {
                    navigate("/blog/edit/" + id);
                });
            }
        }
    }

    const Delete = async () => {
        if (confirm('Smazat příspěvek?')) {
            if (id !== undefined) {
                blogDelete(id).then(() => {
                    navigate("/blog");
                });
            }
        }
    }

    return (
        <div>
            <h1>Editace příspěvku</h1>
            <p>Název: <input type="text" defaultValue={blog.title} onChange={(e) => setBlog({ ...blog, title: e.target.value })} /></p>
            <p>Popis: <textarea defaultValue={blog.text} onChange={(e) => setBlog({ ...blog, text: e.target.value })} /></p>

            {/*<p>Čas: <input type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} /></p>*/}
            {/*<p>Čas: <input type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} /></p>*/}
            <p>Zobrazeno: <input type="checkbox" defaultChecked={blog.isEnabled} onClick={(e) => setBlog({ ...blog, isEnabled: e.currentTarget.checked })} /></p>

            <Button type='button' onClick={Save}>Uložit</Button>
            <Button type='button' onClick={Delete}>Smazat</Button>
            <Link to='/blog'><Button type='button'>Zpět</Button></Link>
        </div>
    )
};

export default BlogEditItemPage;
