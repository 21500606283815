import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import Header from '../../components/Header/Header';
import { getAccessLevel } from '../../services/AccessService';
import { blogGetAll } from '../../services/ApiRequestService';
import { TAccessLevel } from '../../types/TAccessLevel';
import { TBlog } from '../../types/TBlog';


const BlogPage = () => {

    const [blogs, setBlogs] = useState<TBlog[]>([]);

    const accessLeves = getAccessLevel();

    useEffect(() => {

        blogGetAll().then(
            (blogs) => {
                setBlogs(blogs)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);


    return (<React.Fragment>
        <Header />
        {accessLeves.includes(TAccessLevel.LECTOR) &&
            <Link to='/blog/edit/new'>
                <Button type='button'>Nový příspěvek</Button>
            </Link>
        }


        {blogs.map((blog, i) => {
            if (blog.isEnabled || accessLeves.includes(TAccessLevel.LECTOR)) {
                return (
                    <React.Fragment key={blog.id}>
                        <h1>{blog.title}</h1>
                        
                        {
                            accessLeves.includes(TAccessLevel.LECTOR) &&
                            <div>
                                    <p>Zobrazeno: {blog.isEnabled && <React.Fragment>Ano</React.Fragment> || <React.Fragment>Ne</React.Fragment> }</p>
                            </div>
                        }

                        <p>{<div dangerouslySetInnerHTML={{ __html: blog.text }} />}</p>
                        <p>{accessLeves.includes(TAccessLevel.LECTOR) &&
                            <Link to={'/blog/edit/' + blog.id}>
                                <Button type='button'>Editovat</Button>
                            </Link>
                        }
                        </p>
                    </React.Fragment>)
            }
        })}
    </React.Fragment>)
};

export default BlogPage;
