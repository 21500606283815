import * as React from 'react';
import { useEffect, useState } from 'react';
import { paymentGetAllForStudent } from '../../services/ApiRequestService';
import { TPayment } from '../../types/TPayment';

const StudentPaymentsPage = () => {

    const [payments, setPayments] = useState<TPayment[]>([]);

    useEffect(() => {
        paymentGetAllForStudent().then(
            (payments) => {
                setPayments(payments)
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (<div>
        <h1>TODO</h1>
        <p>maybe use same page with lector</p>

        <h1>Nezaplaceno</h1>

        <table width={500}>
            <thead>
                <tr>
                    <th>Datum</th>
                    <th>Částka</th>
                </tr>
            </thead>
            <tbody>
                {payments.map((payment, i) => {
                    return (
                        <tr key={i}>
                            <td>{payment.dateTime}</td>
                            <td>{payment.price}</td>
                        </tr>)
                })}
            </tbody>
        </table>
    </div>)
};

export default StudentPaymentsPage;
