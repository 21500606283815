import * as React from 'react';
import BaseInternalPage from './BaseInternalPage';

const InnerSiteTest = () => {

    return (
        <BaseInternalPage>
            <div>
                <h1>InnerSiteTest fail</h1>
            </div>
        </BaseInternalPage>
    );
};
export default InnerSiteTest