import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { getAccessLevel } from '../services/AccessService';

import menuIcon from '../Images/menu.svg';
import { removeAuthToken } from '../services/LocalStorageService';
import { useState } from 'react';
import { TAccessLevel } from '../types/TAccessLevel';

const NavMenu = () => {
    const [menuStyle, setMenuStyle] = useState('menu-hidden');

    var accessLeves = getAccessLevel();

    let isLoggedIn = false;
    if (accessLeves.includes(TAccessLevel.ADMIN) || accessLeves.includes(TAccessLevel.LECTOR) || accessLeves.includes(TAccessLevel.STUDENT)) {
        isLoggedIn = true;
    }

    const ProcessSignOff = async () => {
        removeAuthToken();
        window.location.href = "/"
    }

    const myFunction = () => {
        if (menuStyle === "menu") {
            setMenuStyle("menu-hidden");
        }            
        else {
            setMenuStyle("menu");
        }
    }

    return (
        <header>

            <div className="menu-top">
                <Link className="mobile-menu-hamburger" to='#' onClick={myFunction}>
                    <img className="nav-icon" src={menuIcon} />
                </Link>


                <div className={menuStyle}>
                    {isLoggedIn ?
                        <NavLink tag={Link} to="/" onClick={ProcessSignOff} >Odhlásit</NavLink> :
                        <NavLink tag={Link} to="/signin" >Přihlásit</NavLink>
                    }
                </div>

            </div>
            
            <div>
                {isLoggedIn ?
                    <ul className={menuStyle}>

                        {(accessLeves.includes(TAccessLevel.LECTOR) || accessLeves.includes(TAccessLevel.STUDENT)) &&
                            <li>
                                <NavLink tag={Link} onClick={myFunction} to="/course/11">Kurzy </NavLink>
                            </li>
                        }

                        {/*{accessLeves.includes(TAccessLevel.LECTOR) || accessLeves.includes(TAccessLevel.ADMIN) ?*/}
                        {/*    <li>*/}
                        {/*        <NavLink tag={Link} onClick={myFunction} to="/overview">Přehled <img className="nav-icon" src={loginIcon} /></NavLink>*/}
                        {/*    </li> : <div />*/}
                        {/*}*/}

                        {accessLeves.includes(TAccessLevel.LECTOR) &&
                            <li>
                                <NavLink tag={Link} onClick={myFunction} to="/lector/calendar">Kalendář</NavLink>
                            </li>
                        }
                        {accessLeves.includes(TAccessLevel.STUDENT) &&
                            <li>
                                <NavLink tag={Link} onClick={myFunction} to="/student/calendar">Kalendář</NavLink>
                            </li>
                        }


                        {/*{accessLeves.includes(TAccessLevel.STUDENT) || accessLeves.includes(TAccessLevel.ADMIN) ?*/}
                        {/*    <li>*/}
                        {/*        <NavLink tag={Link} onClick={myFunction} to="/progress">Pokrok <img className="nav-icon" src={progressIcon} /></NavLink>*/}
                        {/*    </li> : <div />*/}
                        {/*}*/}
                        {accessLeves.includes(TAccessLevel.LECTOR) &&
                            <li>
                                <NavLink tag={Link} onClick={myFunction} to="/students">Studenti</NavLink>
                            </li>
                        }
                        {accessLeves.includes(TAccessLevel.LECTOR) &&
                            <li>
                                <NavLink tag={Link} onClick={myFunction} to="/lector/payments">Platby</NavLink>
                            </li>
                        }
                        {accessLeves.includes(TAccessLevel.STUDENT) &&
                            <li>
                                <NavLink tag={Link} onClick={myFunction} to="/student/payments">Platby</NavLink>
                            </li>
                        }
                        {accessLeves.includes(TAccessLevel.LECTOR) &&
                            <li>
                                <NavLink tag={Link} onClick={myFunction} to="/lector/stats">Statistiky</NavLink>
                            </li>
                        }
                        <li>
                            <NavLink tag={Link} onClick={myFunction} to="/blog ">Blog</NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink tag={Link}onClick={myFunction}  to="/resources">Materiály <img className="nav-icon" src={resourcesIcon} /></NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink tag={Link} onClick={myFunction} to="/settings">Nastavení</NavLink>
                        </li>
                    </ul> :
                    <ul className={menuStyle}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <circle cx="6" cy="6" r="6" fill="#7B2121" />
                        </svg>
                        <div className="vertical-line" />
                        <li>
                            <NavLink tag={Link} onClick={myFunction} to="/about">O mně</NavLink>
                        </li>
                        <div className="vertical-line" />
                        {/*<li>*/}
                        {/*    <NavLink tag={Link} onClick={myFunction} to="/reviews">Recenze <img className="nav-icon" src={reviewsIcon} /></NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink tag={Link} onClick={myFunction} to="/blog ">Blog</NavLink>
                        </li>
                        <div className="vertical-line" />
                        {/*<li>*/}
                        {/*    <NavLink tag={Link} onClick={myFunction} to="/pricelist">Ceník <img className="nav-icon" src={paymentsIcon} /></NavLink>*/}
                        {/*</li>*/}
                        <li>
                            <NavLink tag={Link} onClick={myFunction} to="/contact">Kontakt</NavLink>
                        </li>
                        {/*<li>*/}
                        {/*    <NavLink tag={Link} onClick={myFunction} to="/calendar">Kalendář <img className="nav-icon" src={calendarIcon} /></NavLink>*/}
                        {/*</li>*/}

                        <div className="vertical-line" />
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <circle cx="6" cy="6" r="6" fill="#7B2121" />
                        </svg>
                    </ul>
                }
            </div>
                    {/*</Collapse>*/}
                {/*</Container>*/}
            {/*</Navbar>*/}
        </header>
    );
}

export default NavMenu
