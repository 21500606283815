import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { courseAdd, courseDelete, courseGet, courseUpdate } from '../../services/ApiRequestService';
import { TCourse } from '../../types/TCourse';

const CourseEditPage = () => {

    let { id } = useParams();
    if (id === undefined) {
        return <p>No course</p>;
    }

    const [course, setCourse] = useState<TCourse>({} as TCourse);
    const navigate = useNavigate();

    if (id !== 'new') {
        useEffect(() => {
            if (id === undefined) {
                return;
            }

            courseGet(id).then((course) => {
                if (course !== null) {
                    setCourse(course);
                }
            });
            return () => {
                //alert('Will unmount');
            };
        }, []);
    }

    const Save = async () => {
        if (id === 'new') {
            //insert
            if (id !== undefined) {
                courseAdd(course).then((course) => {
                    if (course !== null) {
                        navigate("/course/" + course.id);
                    }
                    else {
                        navigate("/courses");
                    }
                });
            }
        }
        else {
            //update
            if (id !== undefined) {
                courseUpdate(course).then(() => {
                    navigate("/course/" + id);
                });
            }
        }
    }

    const Delete = async () => {
        if (confirm('Smazat kurz?')) {
            if (id !== undefined) {
                courseDelete(id).then(() => {
                    navigate("/courses");
                });
            }
        }
    }

    return (
        <div>
            <h1>Editace Kurzu</h1>
            <p>Název: <input type="text" defaultValue={course.name} onChange={(e) => setCourse({ ...course, name: e.target.value })} /></p>
            <p>Popis: <input type="text" defaultValue={course.description} onChange={(e) => setCourse({ ...course, description: e.target.value })} /></p>
            <p>Jazyk: <input name="language" type="text" defaultValue={course.language} onChange={(e) => setCourse({ ...course, language: e.target.value })} /></p>
            <p>Cena: <input type="number" defaultValue={course.price} step={50} onChange={(e) => setCourse({ ...course, price: Number(e.target.value) })} /></p>

            <Button type='button' onClick={Save}>Uložit</Button>
            <Button type='button' onClick={Delete}>Smazat</Button>
            <Link to='/courses'><Button type='button'>Zpět</Button></Link>
        </div>
    )
};

export default CourseEditPage;