import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { userGetCurent, userUpdate } from '../../services/ApiRequestService';
import { TUser } from '../../types/TUser';
//import { Link } from 'react-router-dom';

export const PersonalSettingsEdit = () => {

    const [user, setUser] = useState<TUser>({} as TUser);
    const navigate = useNavigate();

    useEffect(() => {

        userGetCurent().then((user) => {
            if (user !== null) {
                setUser(user);
            }
        });
        return () => {
            //alert('Will unmount');
        };
    }, []);

    const Save = async () => {
        //update
        userUpdate(user).then(() => {
            navigate("/");
        });
    }


    return (
        <div>
            <h1>Osobní nastavení</h1>
            <p>Email: <input type="text" value={user.email} onChange={(e) => setUser({ ...user, email: e.target.value })} /></p>
            <p>Jméno: <input name="firstName" type="text" value={user.firstName} onChange={(e) => setUser({ ...user, firstName: e.target.value })} /></p>
            <p>Příjmení: <input name="lastName" type="text" value={user.lastName} onChange={(e) => setUser({ ...user, lastName: e.target.value })} /></p>
            <p>Fotka: student.photo</p>

            <Button type='button' onClick={Save}>Uložit TODO</Button>
            <Link to='show'><Button type='button'>Zpět TODO</Button></Link>
        </div>
    );
};

export default PersonalSettingsEdit
