import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { userGetAll } from '../../../services/ApiRequestService';
import { TUser } from '../../../types/TUser';

const TodoList = () => {
        
    return (
        <div>
            <h1>TODO:</h1>
            <p>Task to finish before publish</p>
            <p>Mandatory - common</p>
            <ul>
                <li>forgot password logic</li>
                <li>login logic</li>
                <li>for every add / remove check role</li>
            </ul>
            <p>Mandatory - visitor</p>
            <ul>
                <li>all pages in menu has to be finished.</li>
                <li>calendar should show available time.</li>
            </ul>
            <p>mandatory - admin</p>
            <ul>
                <li>add user</li>
                <li>modify user</li>
                <li>remove user</li>
                <li>add admin role</li>
                <li>add lector role</li>
                <li>add student role</li>
                <li>remove lector role</li>
                <li>remove student role</li>
                <li>remove admin role</li>
                <li>remove admin role from self account is not possible</li>
                <li>send invite to user</li>
                <li>send invite email content</li>
            </ul>
            <p>mandatory - lector</p>
            <ul>
                <li>add course</li>
                <li>modify course</li>
                <li>remove course</li>
                <li>add lesson</li>
                <li>modify lesson</li>
                <li>remove lesson</li>
                <li>add lesson repeat</li>
                <li>modify lesson repeat</li>
                <li>remove lesson repeat</li>
                <li>add available time</li>
                <li>modify available time</li>
                <li>remove available time</li>
                <li>add student</li>
                <li>modify student</li>
                <li>remove student</li>
                <li>payments shows missing payments</li>
                <li>calendar shows available time and ocupied time by who</li>
                <li>calendar shows available time and ocupied time by who</li>
                <li>settings edit own info only</li>
                <li>send invite to student</li>
                <li>send invite email content</li>
            </ul>
            <p>optional - student</p>
            <ul>
                <li></li>
            </ul>
        </div>
    );
};

export default TodoList