import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getAccessLevel } from '../../services/AccessService';
import { lessonGet } from '../../services/ApiRequestService';
import { TAccessLevel } from '../../types/TAccessLevel';
import { TLesson } from '../../types/TLesson';

const LessonPage = () => {

    var accessLevels = getAccessLevel();

    var accessLevels = getAccessLevel();
    if (!accessLevels.includes(TAccessLevel.LECTOR) && !accessLevels.includes(TAccessLevel.STUDENT)) {
        return (<p>Invalid role</p>)
    }

    let { courseId, classroomId, id } = useParams();

    if (id === undefined) {
        return (<p>No lesson</p>);
    }

    const [lesson, setLesson] = useState<TLesson>({} as TLesson);

    useEffect(() => {
        if (id === undefined) {
            return;
        }

        lessonGet(id).then((lesson) => {
            if (lesson !== null) {
                setLesson(lesson);
            }
        });

        return () => {
            //alert('Will unmount');
        };
    }, []);

    return (
        <div>
            <h1>Lekce</h1>
            <p>Název: {lesson.name}</p>
            <p>Popis: {lesson.description}</p>
            <p>Start: {lesson.start}</p>
            <p>Délka: {lesson.duration}</p>
            <p>Cena: {lesson.price}</p>
            <p>Placeno: {lesson.payed ? "Ano" : "Ne"}</p>
            <p>Online: {lesson.isOnline ? "Ano" : "Ne"}</p>
            <p>Zkušební: {lesson.isTrial ? "Ano" : "Ne"}</p>
            <p>Status lekce:{
                lesson.state === 0 ? "Ještě neproběhla" :
                lesson.state === 1 ? "Účast" :
                lesson.state === 2 ? "Zrušeno" :
                lesson.state === 3 ? "Zrušeno pozdě" :
                lesson.state === 4 ? "Zrušeno lektorem" : ""
            }</p>

            {accessLevels.includes(TAccessLevel.LECTOR) &&
                <Link to={"/course/" + courseId + "/classroom/" + classroomId + '/lesson/edit/' + id}><Button type='button'>Editovat</Button></Link>
            }
            
            <Link to={"/course/" + courseId + "/classroom/" + classroomId}><Button type='button'>Zpět</Button></Link>
        </div>
    )
};

export default LessonPage;