import * as React from 'react';
import Header from '../../components/Header/Header';

const Contact = () => (
    <div>
        <Header />
        <h1>Kontakt</h1>
        <p>V případě zájmu mne můžete kontaktovat na níže uvedených kontaktech:</p>
        <p>Tel: <a href="tel:+420608970611">+420 608 970 611</a></p>
        <p>Email: <a href="info@mluvanglicky.eu">info@mluvanglicky.eu</a></p>
        
        
    </div>
);

export default Contact;
