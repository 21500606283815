import { TCredentials } from '../types/TCredentials';
import { TAuthToken } from '../types/TAuthToken';
import { getAuthToken } from '../services/LocalStorageService';
import { TUser } from '../types/TUser';
import { TCourse } from '../types/TCourse';
import { TLesson } from '../types/TLesson';
import { TRole } from '../types/TRole';
import { TPayment } from '../types/TPayment';
import { TDedicatedTime } from '../types/TDedicatedTime';
import { TSubmitNewPasswordRequest } from '../types/TSubmitNewPasswordRequest';
import { TSendInviteLinkRequest } from '../types/TSendInviteLinkRequest';
import { TSendResetPasswordLinkRequest } from '../types/TSendResetPasswordLinkRequest';
import { TEventResponse } from '../types/TEventResponse';
import { TStudentStats } from '../types/TStudentStats';
import { TClassroom } from '../types/TClassroom';
import { TBlog } from '../types/TBlog';
import { TStats } from '../types/TStats';


//course
export async function courseGetAll(): Promise<TCourse[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TCourse[]>('api/course/getAll', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const courses: TCourse[] = [];
    return courses;
}

export async function courseGetAllForLector(): Promise<TCourse[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TCourse[]>('api/course/getAllForLector', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const courses: TCourse[] = [];
    return courses;
}

export async function courseGetAllForStudent(): Promise<TCourse[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TCourse[]>('api/course/getAllForStudent', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const courses: TCourse[] = [];
    return courses;
}

export async function courseGet(id: string): Promise<TCourse | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TCourse>('api/course/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function courseAdd(course: TCourse): Promise<TCourse | null> {
    var body = JSON.stringify(course);
    var data = await apiAuthRequest<TCourse>('api/course/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function courseUpdate(course: TCourse): Promise<TCourse | null> {
    var body = JSON.stringify(course);
    var data = await apiAuthRequest<TCourse>('api/course/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function courseDelete(id: string) {
    var req = {
        courseId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/course/delete', body);
}

//dedicated time
export async function dedicatedTimeGetAllForCourse(courseId: string): Promise<TDedicatedTime[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TDedicatedTime[]>('api/dedicatedTime/GetAllForCourse/' + courseId, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const dedicatedTimes: TDedicatedTime[] = [];
    return dedicatedTimes;
}

export async function dedicatedTimeAdd(dedicatedTime: TDedicatedTime): Promise<TDedicatedTime | null> {
    var body = JSON.stringify(dedicatedTime);
    var data = await apiAuthRequest<TDedicatedTime>('api/dedicatedTime/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function dedicatedTimeUpdate(dedicatedTime: TDedicatedTime): Promise<TDedicatedTime | null> {
    var body = JSON.stringify(dedicatedTime);
    var data = await apiAuthRequest<TDedicatedTime>('api/dedicatedTime/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function dedicatedTimeDelete(id: string) {
    var req = {
        dedicatedTimeId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/dedicatedTime/delete', body);
}

//lesson
export async function lessonGetAll(): Promise<TLesson[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TLesson[]>('api/lesson/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const lessons: TLesson[] = [];
    return lessons;
}

export async function lessonGetAllForClassroom(classroomId: string): Promise<TLesson[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TLesson[]>('api/lesson/GetAllForClassroom/' + classroomId, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const lessons: TLesson[] = [];
    return lessons;
}

export async function lessonGet(id: string): Promise<TLesson | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TLesson>('api/lesson/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function lessonAdd(lesson: TLesson): Promise<TLesson | null> {
    var body = JSON.stringify(lesson);
    var data = await apiAuthRequest<TLesson>('api/lesson/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function lessonUpdate(lesson: TLesson): Promise<TLesson | null> {
    var body = JSON.stringify(lesson);
    var data = await apiAuthRequest<TLesson>('api/lesson/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function lessonDelete(id: string) {
    var req = {
        lessonId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/lesson/delete', body);
}


//blog
export async function blogGetAll(): Promise<TBlog[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TBlog[]>('api/blog/getAll', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const blog: TBlog[] = [];
    return blog;
}

export async function blogGet(id: string): Promise<TBlog | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TBlog>('api/blog/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function blogAdd(blog: TBlog): Promise<TBlog | null> {
    var body = JSON.stringify(blog);
    var data = await apiAuthRequest<TBlog>('api/blog/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function blogUpdate(blog: TBlog): Promise<TBlog | null> {
    var body = JSON.stringify(blog);
    var data = await apiAuthRequest<TBlog>('api/blog/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function blogDelete(id: string) {
    var req = {
        blogId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/blog/delete', body);
}

//classroom
export async function classroomGetAll(): Promise<TClassroom[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TClassroom[]>('api/classroom/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const classroom: TClassroom[] = [];
    return classroom;
}

export async function classroomGetAllForCourse(courseId: string): Promise<TClassroom[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TClassroom[]>('api/classroom/GetAllForCourse/' + courseId, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const classroom: TClassroom[] = [];
    return classroom;
}

export async function classroomGet(id: string): Promise<TClassroom | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TClassroom>('api/classroom/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function classroomAdd(classroom: TClassroom): Promise<TClassroom | null> {
    var body = JSON.stringify(classroom);
    var data = await apiAuthRequest<TClassroom>('api/classroom/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function classroomUpdate(classroom: TClassroom): Promise<TClassroom | null> {
    var body = JSON.stringify(classroom);
    var data = await apiAuthRequest<TClassroom>('api/classroom/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function classroomDelete(id: string) {
    var req = {
        classroomId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/classroom/delete', body);
}

//role
export async function roleGetAll(): Promise<TRole[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TRole[]>('api/role/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const roles: TRole[] = [];
    return roles;
}

//user
export async function userGetAll(): Promise<TUser[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser[]>('api/user/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const users: TUser[] = [];
    return users;
}

export async function userGetAllStudents(): Promise<TUser[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser[]>('api/user/getallstudents', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const lectors: TUser[] = [];
    return lectors;
}

export async function userGetAllStudentsForClassroom(classroomId: string): Promise<TUser[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser[]>('api/user/getAllStudentsForClassroom/' + classroomId, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const lectors: TUser[] = [];
    return lectors;
}

export async function userGetAllLectors(): Promise<TUser[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser[]>('api/user/getalllectors', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const lectors: TUser[] = [];
    return lectors;
}

export async function userGet(id: string): Promise<TUser | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser>('api/user/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userGetCurent(): Promise<TUser | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser>('api/user/getcurent', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userAdd(user: TUser): Promise<TUser | null> {
    var body = JSON.stringify(user);
    var data = await apiAuthRequest<TUser>('api/user/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userUpdate(user: TUser): Promise<TUser | null> {
    var body = JSON.stringify(user);
    var data = await apiAuthRequest<TUser>('api/user/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function userDelete(id: string) {
    var req = {
        userId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/user/delete', body);
}

//student
export async function studentGetAll(): Promise<TUser[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser[]>('api/lector/student/getall', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const users: TUser[] = [];
    return users;
}

export async function studentGet(id: string): Promise<TUser | null> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TUser>('api/lector/student/get/' + id, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function studentAdd(user: TUser): Promise<TUser | null> {
    var body = JSON.stringify(user);
    var data = await apiAuthRequest<TUser>('api/lector/student/add/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function studentUpdate(user: TUser): Promise<TUser | null> {
    var body = JSON.stringify(user);
    var data = await apiAuthRequest<TUser>('api/lector/student/update', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function studentDelete(id: string) {
    var req = {
        studentId: id
    }

    var body = JSON.stringify(req);
    await apiAuthRequest('api/lector/student/delete', body);
}

//calendar
export async function calendarGetNearForStudent(): Promise<TEventResponse[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TEventResponse[]>('api/calendar/getnearforstudent', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const events: TEventResponse[] = [];
    return events;
}

export async function calendarGetNearForLector(): Promise<TEventResponse[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TEventResponse[]>('api/calendar/getnearforlector', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const events: TEventResponse[] = [];
    return events;
}

export async function calendarGetNearForVisitor(): Promise<TEventResponse[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TEventResponse[]>('api/calendar/GetNearForVisitor', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const events: TEventResponse[] = [];
    return events;
}

//payments
export async function paymentGetAllForStudent(): Promise<TPayment[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TPayment[]>('api/payment/getallforstudent', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const payments: TPayment[] = [];
    return payments;
}

export async function paymentGetAllForLector(): Promise<TPayment[]> {
    var body = JSON.stringify('');
    var data = await apiAuthRequest<TPayment[]>('api/payment/getallforlector', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const payments: TPayment[] = [];
    return payments;
}

//stats
export async function statsGetUserStats(studentId: string, from: string, to: string): Promise<TStudentStats | null> {
    var body = JSON.stringify({
        from: from,
        to: to
    });
    var data = await apiAuthRequest<TStudentStats>('api/stats/getStudentStats/' + studentId, body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    return null;
}

export async function statsGet(from: string, to: string, studentIds: string[]): Promise<TStats[]> {
    var body = JSON.stringify({
        from: from,
        to: to,
        studentIds: studentIds
    });
    var data = await apiAuthRequest<TStats[]>('api/stats/getStats/', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const stats: TStats[] = [];
    return stats;
}

//authentication
export async function signUp(credentials: TCredentials) {
    var body = JSON.stringify(credentials);
    await apiRequest('api/authentication/signup', body);
}

export async function signIn(credentials: TCredentials): Promise<TAuthToken> {
    var body = JSON.stringify(credentials);
    var data = await apiRequest<TAuthToken>('api/authentication/signin', body);

    if (data !== undefined)
        return data;

    //TODO: remove if it is possible
    const authToken: TAuthToken = {
        successful: false,
        authToken: undefined
    };
    return authToken;
}

export async function sendResetPasswordLink(sendResetPasswordLinkRequest: TSendResetPasswordLinkRequest) {
    var body = JSON.stringify(sendResetPasswordLinkRequest);
    await apiRequest('api/authentication/SendResetPasswordLink', body);
}

export async function sendInviteLink(sendInviteLinkRequest: TSendInviteLinkRequest) {
    var body = JSON.stringify(sendInviteLinkRequest);
    await apiAuthRequest('api/authentication/SendInviteLink', body);
}

export async function submitNewPassword(submitNewPasswordRequest: TSubmitNewPasswordRequest) {
    var body = JSON.stringify(submitNewPasswordRequest);
    await apiRequest('api/authentication/SubmitNewPassword', body);
}

async function apiRequest<T>(url: string, body: string) {
    
    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: body
    });

    if (response.status == 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {

            const json = await response.json();
            return json as T;
        }

        return;
    }
    else {
        const text = await response.text();
        alert(text);
    }
}

async function apiAuthRequest<T>(url: string, body: string) {
    const authToken = getAuthToken();
        
    //find better solution
    let at = '';
    if (authToken !== undefined) {
        if (authToken.authToken !== undefined) {
            at = authToken.authToken;
        }
    }

    const response = await fetch(url, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + at
        },
        body: body
    });
        
    if (response.status == 200) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {

            const json = await response.json();
            return json as T;
        }

        return;
    }
    else {
        const text = await response.text();
        alert(text);
    }
}
